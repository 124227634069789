export function counter() {
	const counterNumsElArr = document.querySelectorAll('[data-counter-target]')

	if (!counterNumsElArr.length) return

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				function counter(el, duration) {
					const targetNumber = parseFloat(el.dataset.counterTarget)
					const startNumber = parseFloat(el.textContent) || 0
					const hasDecimals = targetNumber % 1 !== 0
					const startTime = performance.now()
					const numDigits = targetNumber.toString().length

					function padNumber(num, length) {
						return num.toString().padStart(length, '0')
					}

					function updateCounter(currentTime) {
						const elapsedTime = currentTime - startTime
						const progress = Math.min(elapsedTime / duration, 1)

						let value = progress * (targetNumber - startNumber) + startNumber

						if (hasDecimals) {
							value = value.toFixed(2)
						} else {
							value = Math.floor(value)
						}

						// Pad the number with leading zeros based on the target number's length
						el.textContent = padNumber(value, numDigits)

						if (progress < 1 || (hasDecimals && parseFloat(el.textContent) < targetNumber)) {
							requestAnimationFrame(updateCounter)
						}
					}

					requestAnimationFrame(updateCounter)
				}

				counter(entry.target, 1000)
				observer.unobserve(entry.target)
			}
		})
	})

	counterNumsElArr.forEach(counterNumEl => {
		observer.observe(counterNumEl)
	})
}