/*
 * Usage:
 * 1. Add `data-dialog-container` attribute to the container of the dialog box.
 * 2. Add `data-dialog-open` attribute to button/s that open the dialog box.
 * 3. Add `data-dialog-close` attribute to button/s that close the dialog box.
 *
 * Example:
 * <button data-dialog-open type="button">Open dialog</button>
 *
 * <div data-dialog-container>
 *   <h2>Dialog title</h2>
 *   <button data-dialog-close type="button">Close</button>
 * </div>
 *
 * Explanation:
 * This code creates a simple dialog box that can be opened and closed using buttons with specific attributes. Clicking the button/s with `data-dialog-open` attribute will show the dialog box (container with `data-dialog-container` attribute). Clicking the button/s with `data-dialog-close` attribute will hide the dialog box.
 */

const containerForTitle = document.querySelector('[data-dialog-container-for-title]')
const containerForSubtitle = document.querySelector('[data-dialog-container-for-subtitle]')
const containerForImage = document.querySelector('[data-dialog-container-for-image]')
const containerForContent = document.querySelector('[data-dialog-container-for-content]')
const containerForScoreBox = document.querySelector('[data-dialog-container-for-score-box]')

export function dialog() {
	const openButtons = document.querySelectorAll('[data-dialog-open]')
	const closeButtons = document.querySelectorAll('[data-dialog-close]')
	const dialogContainer = document.querySelector('[data-dialog-container]')

	if (!openButtons || !closeButtons || !dialogContainer) {
		return
	}

	let focusAnchor
	let focusableElements
	let firstFocusableElement
	let lastFocusableElement
	const backdrop = createBackdrop()
	let resizeObserver

	dialogContainer.setAttribute('role', 'dialog')
	dialogContainer.setAttribute('aria-modal', 'true')

	openButtons.forEach(openButton => {
		openButton.addEventListener('keydown', e => {
			if (e.key === 'Enter') {
				e.preventDefault()
				openButton.click()
			}
		})

		openButton.addEventListener('click', e => {
			const content = openButton.nextElementSibling?.innerHTML // closest hidden content
			const title = openButton.querySelector('[data-dialog-title]')?.innerText
			const subtitle = openButton.querySelector('[data-dialog-subtitle]')?.innerText
			const image = openButton.querySelector('[data-dialog-image]')?.innerHTML
			const scoreBox = openButton.querySelector('[data-dialog-score-box]')?.innerHTML

			containerForContent.innerHTML = content ?? ''
			containerForTitle.innerText = title ?? ''
			containerForSubtitle.innerText = subtitle ?? ''
			containerForImage.innerHTML = image ?? ''
			containerForScoreBox.innerHTML = scoreBox ?? ''

			e.preventDefault()
			openDialog()
			focusAnchor = openButton

			focusableElements = dialogContainer.querySelectorAll(
				'button, a[href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
			)

			firstFocusableElement = focusableElements[0]
			lastFocusableElement = focusableElements[focusableElements.length - 1]
		})
	})

	closeButtons.forEach(closeButton => {
		closeButton.addEventListener('click', closeDialog)
	})

	backdrop.addEventListener('click', closeDialog)
	dialogContainer.addEventListener('keydown', focusTrap)

	function closeWithEscHandler(e) {
		if (e.code === 'Escape') closeDialog()
	}

	function openDialog() {
		dialogContainer.addEventListener('transitionend', () => firstFocusableElement.focus(), { once: true })
		dialogContainer.classList.add('active')
		backdrop.classList.add('active')
		document.addEventListener('keydown', closeWithEscHandler)
	}

	function closeDialog() {
		dialogContainer.classList.remove('active')
		backdrop.classList.remove('active')
		document.removeEventListener('keydown', closeWithEscHandler)
		focusAnchor.focus({
			preventScroll: true,
		})

		if (resizeObserver) {
			resizeObserver.disconnect()
			resizeObserver = null
		}

		// scrollbar position fix
		dialogContainer.addEventListener(
			'transitionend',
			() => {
				dialogContainer.scrollTop = 0
				dialogContainer.style.height = null
			},
			{ once: true }
		)
	}

	function createBackdrop() {
		const backdrop = document.createElement('div')
		backdrop.classList.add('dialog__backdrop')
		dialogContainer.before(backdrop)
		return backdrop
	}

	function focusTrap(e) {
		if (e.code === 'Tab' && e.shiftKey && document.activeElement === firstFocusableElement) {
			e.preventDefault()
			lastFocusableElement.focus()
		} else if (e.code === 'Tab' && !e.shiftKey && document.activeElement === lastFocusableElement) {
			e.preventDefault()
			firstFocusableElement.focus()
		}
	}
}