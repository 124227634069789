export function rotatingText() {
	document.addEventListener('DOMContentLoaded', function () {
		const rotatingTextElements = document.querySelectorAll('[data-rotating-text]')

		rotatingTextElements.forEach(dataTestElement => {
			const texts = JSON.parse(dataTestElement.getAttribute('data-rotating-text'))
			const speed = parseInt(dataTestElement.getAttribute('data-rotating-text-speed'), 10)
			let currentIndex = 0

			// Function to measure the width of a given text
			function measureTextWidth(text) {
				const tempElement = document.createElement('span')
				tempElement.style.visibility = 'hidden'
				tempElement.style.position = 'absolute'
				tempElement.style.whiteSpace = 'nowrap'
				tempElement.style.font = window.getComputedStyle(dataTestElement).font
				tempElement.textContent = text
				document.body.appendChild(tempElement)
				const width = tempElement.offsetWidth
				document.body.removeChild(tempElement)
				return width
			}

			function setText(text) {
				const textWidth = measureTextWidth(text)
				dataTestElement.style.width = `${textWidth}px`
				dataTestElement.textContent = text
			}

			function setInitialText() {
				setText(texts[currentIndex])
				dataTestElement.style.opacity = 1
			}

			function cycleTexts() {
				dataTestElement.style.opacity = 0

				setTimeout(() => {
					currentIndex = (currentIndex + 1) % texts.length
					setText(texts[currentIndex])
					dataTestElement.style.opacity = 1
				}, 375) // Match this duration with CSS transition
			}

			setInitialText()
			setInterval(cycleTexts, speed)
		})
	})
}
